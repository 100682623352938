/**
 * Wallet
 * @author Simpli CLI generator
 */
import {Request, ResponseSerialize} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {Dapp} from '@/model/resource/Dapp'
import {WalletCollection} from '@/model/collection/WalletCollection'
import {BlockchainVersion} from '@/model/resource/BlockchainVersion'
import {DappConnection} from '@/model/resource/DappConnection'
import {PlatformType} from '@/model/resource/PlatformType'

/* TODO: review generated class */
export class Wallet implements IResource {
  idWalletPk: number = 0

  @ResponseSerialize(Dapp)
  dappWallet: Dapp[] | null = null

  logoUrl: string | null = null
  title: string | null = null
  active: boolean | null = null

  websiteUrl: string | null = null
  order: number | null = null
  description: string | null = null
  @ResponseSerialize(BlockchainVersion)
  blockchainVersion: BlockchainVersion[] = []
  @ResponseSerialize(PlatformType)
  platformType: PlatformType[] = []
  @ResponseSerialize(DappConnection)
  dappConnection: DappConnection[] = []

  get $id() {
    return this.idWalletPk
  }
  set $id(val) {
    this.idWalletPk = val
  }
  get $tag() {
    return String(this.title)
  }

  get blockchainVersionTitles() {
    return this.blockchainVersion?.map(resource => resource.title).join(', ')
  }

  get platformTypeTitles() {
    return this.platformType?.map(resource => resource.title).join(', ')
  }

  get dappConnectionTitles() {
    return this.dappConnection?.map(resource => resource.title).join(', ')
  }

  /**
   * Gets a instance of a given ID of Wallet
   */
  async getWallet(id: number) {
    return await Request.get(`/client/wallet/${id}`)
      .name('getWallet')
      .as(this)
      .getData()
  }

  /**
   * Deletes a instance of a given ID of Wallet
   */
  async removeWallet() {
    return await Request.delete(`/client/wallet/${this.$id}`)
      .name('removeWallet')
      .asNumber()
      .getData()
  }

  /**
   * Lists the instances of Wallet
   */
  static async listWallet(params: any) {
    return await Request.get(`/client/wallet`, {params})
      .name('listWallet')
      .as(WalletCollection)
      .getData()
  }

  /**
   * Persists a new instance of Wallet. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persistWallet() {
    return await Request.post(`/client/wallet`, this)
      .name('persistWallet')
      .asNumber()
      .getData()
  }

  /**
   * Lists the instances of Wallet to export as a file
   */
  static async listExportWallet(params: any) {
    return await Request.get(`/client/wallet/export`, {params})
      .name('listExportWallet')
      .as(WalletCollection)
      .getData()
  }

  /**
   * Increments the order of wallet
   */
  async changeOrder() {
    return await Request.post(`/client/wallet/${this.$id}/order`, this)
      .name('changeOrder')
      .asNumber()
      .getData()
  }
}
