/**
 * DappSocial
 * @author Simpli CLI generator
 */
import {$} from '@/facade'
import {Request, ResponseSerialize} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {Dapp} from '@/model/resource/Dapp'
import {SocialType} from '@/model/resource/SocialType'
import {DappSocialCollection} from '@/model/collection/DappSocialCollection'
import {plainToClass} from 'class-transformer'

/* TODO: review generated class */
export class DappSocial implements IResource {
  @ResponseSerialize(Dapp)
  dapp: Dapp | null = null

  @ResponseSerialize(SocialType)
  socialType: SocialType | null = null

  url: string | null = null

  get $id() {
    /* TODO: define the ID */
    return 0
  }
  set $id(val) {
    /* TODO: define the ID */
  }
  get $tag() {
    return String(this.$id)
  }

  get idDappFk() {
    if (!this.dapp) return 0
    return this.dapp.$id
  }
  set idDappFk(val) {
    if (!this.dapp) this.dapp = new Dapp()
    this.dapp.$id = val
  }

  get idSocialTypeFk() {
    if (!this.socialType) return 0
    return this.socialType.$id
  }
  set idSocialTypeFk(val) {
    if (!this.socialType) this.socialType = new SocialType()
    this.socialType.$id = val
  }

  /**
   * Gets a instance of a given ID of DappSocial
   */
  async getDappSocial(id1: number, id2: number) {
    const response = await Request.get(`/client/dapp-social/${id1}/${id2}`)
      .name('getDappSocial')
      .as(this)
      .getData()
    this.dapp = plainToClass(Dapp, this.dapp)
    return response
  }

  /**
   * Lists the instances of DappSocial
   */
  static async listDappSocial(params: any) {
    return await Request.get(`/client/dapp-social`, {params})
      .name('listDappSocial')
      .as(DappSocialCollection)
      .getData()
  }

  /**
   * Persists a new instance of DappSocial. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persistDappSocial() {
    return await Request.post(`/client/dapp-social`, this)
      .name('persistDappSocial')
      .asNumber()
      .getData()
  }

  /**
   * Lists the instances of DappSocial to export as a file
   */
  static async listExportDappSocial(params: any) {
    return await Request.get(`/client/dapp-social/export`, {params})
      .name('listExportDappSocial')
      .as(DappSocialCollection)
      .getData()
  }

  /**
   * Deletes a instance of a given ID of Wallet
   */
  async removeDappSocial(id1: number, id2: number) {
    return await Request.delete(`/client/dapp-social/${id1}/${id2}`)
      .name('removeDappSocial')
      .asNumber()
      .getData()
  }
}
