/**
 * CategoryCollection
 *
 * @author Simpli CLI generator
 */
import {$} from '@/facade'
import {
  HttpExclude,
  Request,
  RequestExpose,
  ResponseSerialize,
} from '@simpli/serialized-request'
import {PageCollection} from '@simpli/resource-collection'
import {Category} from '@/model/resource/Category'

/* TODO: review generated class */
@HttpExclude()
export class CategoryCollection extends PageCollection<Category> {
  constructor() {
    super(Category)
  }

  resource?: ICategoryCollectionResourcesHolder

  queryAsPage() {
    return this.listCategory()
  }

  async listCategory() {
    return await Request.get(`/client/category`, {params: this.params})
      .name('listCategory')
      .as(this)
      .getResponse()
  }

  async listExportCategory() {
    return await Request.get(`/client/category/export`, {params: this.params})
      .name('listExportCategory')
      .as(this)
      .getResponse()
  }
}

export interface ICategoryCollectionResourcesHolder {}
