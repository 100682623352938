/**
 * NntTagCollection
 *
 * @author Simpli CLI generator
 */
import {$} from '@/facade'
import {
  HttpExclude,
  Request,
  RequestExpose,
  ResponseSerialize,
} from '@simpli/serialized-request'
import {PageCollection} from '@simpli/resource-collection'
import {NntTag} from '@/model/resource/NntTag'

/* TODO: review generated class */
@HttpExclude()
export class NntTagCollection extends PageCollection<NntTag> {
  constructor() {
    super(NntTag)
  }

  resource?: INntTagCollectionResourcesHolder

  queryAsPage() {
    return this.listNntTag()
  }

  async listNntTag() {
    return await Request.get(`/client/nnt-tag`, {params: this.params})
      .name('listNntTag')
      .as(this)
      .getResponse()
  }

  async listExportNntTag() {
    return await Request.get(`/client/nnt-tag/export`, {params: this.params})
      .name('listExportNntTag')
      .as(this)
      .getResponse()
  }
}

export interface INntTagCollectionResourcesHolder {}
