/**
 * Contract
 * @author Lopes
 */
import {Request, ResponseSerialize} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {Dapp} from '@/model/resource/Dapp'
import {ContractCollection} from '@/model/collection/ContractCollection'
import {BlockchainVersion} from '@/model/resource/BlockchainVersion'
import {ContractNep17} from '@/model/resource/ContractNep17'
import {PriceHistory} from '@/model/resource/PriceHistory'

export class Contract implements IResource {
  idContractPk: number = 0

  imageUrl: string | null = null

  @ResponseSerialize(BlockchainVersion)
  blockchainVersion: BlockchainVersion | null = null

  @ResponseSerialize(ContractNep17)
  contractNep17: ContractNep17 | null = null

  @ResponseSerialize(Dapp)
  dapp: Dapp | null = null

  rank: number | null = null
  recentTransactionCount: number | null = null
  referenceTransactionCount: number | null = null
  percentageGrowth: number | null = null
  title: string | null = null
  hash: string | null = null
  hashes: string[] | null = null
  deploymentDate: string | null = null
  active: boolean | null = null

  get order() {
    if (!this.contractNep17) return 0

    return this.contractNep17.order
  }

  set order(val) {
    if (!this.contractNep17) return

    this.contractNep17.order = val
  }

  get $id() {
    return this.idContractPk
  }

  set $id(val) {
    this.idContractPk = val
  }

  get $tag() {
    return `${this.title} ${this.hash ? ' - ' + this.hash : ''}`
  }

  get idDappFk() {
    if (!this.dapp) return 0
    return this.dapp.$id
  }

  set idDappFk(val) {
    if (!this.dapp) this.dapp = new Dapp()
    this.dapp.$id = val
  }

  static async fetchContracts() {
    return await Request.post(`/client/contract/job/fetch-new-contracts`)
      .name('fetchContracts')
      .asVoid()
      .getResponse()
  }

  async getContract(id: number) {
    return await Request.get(`/client/contract/${id}`)
      .name('getContract')
      .as(this)
      .getData()
  }

  async persistContract() {
    return await Request.post(`/client/contract`, this)
      .name('persistContract')
      .asNumber()
      .getData()
  }

  async removeContract(idDapp: number, idContract: number) {
    return await Request.delete(`/client/contract/${idDapp}/${idContract}`)
      .name('removeContract')
      .asNumber()
      .getData()
  }

  async softDeleteOrRecoverContract(idContract: number, active: boolean) {
    return await Request.post(`/client/contract/active/${idContract}/${active}`)
      .name('softDeleteOrRecoverContract')
      .asNumber()
      .getData()
  }
}
