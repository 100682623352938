/**
 * WalletCollection
 *
 * @author Simpli CLI generator
 */
import {HttpExclude, Request, RequestExpose} from '@simpli/serialized-request'
import {ExpansibleCollection} from '@simpli/resource-collection'
import {Wallet} from '@/model/resource/Wallet'
import {DappCollection} from '@/model/collection/DappCollection'
import {BlockchainVersionCollection} from '@/model/collection/BlockchainVersionCollection'
import {PlatformTypeCollection} from '@/model/collection/PlatformTypeCollection'
import {DappConnectionCollection} from '@/model/collection/DappConnectionCollection'

/* TODO: review generated class */
@HttpExclude()
export class WalletCollection extends ExpansibleCollection<Wallet> {
  constructor() {
    super(Wallet)
  }

  resource?: IWalletCollectionResourcesHolder

  @RequestExpose() idBlockchainVersionFk: number[] = []
  @RequestExpose() idPlatformTypeFk: number[] = []
  @RequestExpose() idDappConnectionFk: number[] = []
  @RequestExpose() isMobile: Boolean | null = null
  @RequestExpose() isDesktop: Boolean | null = null
  @RequestExpose() isWeb: Boolean | null = null
  @RequestExpose() isHardware: Boolean | null = null

  get walletBlockchainVersion() {
    return (
      this.resource?.collectionBlockchainVersion.getManyIds(
        this.idBlockchainVersionFk
      ) ?? null
    )
  }

  set walletBlockchainVersion(input) {
    this.idBlockchainVersionFk = input?.map(item => item?.$id) ?? []
  }

  get walletPlatformType() {
    return (
      this.resource?.collectionPlatformType.getManyIds(this.idPlatformTypeFk) ??
      null
    )
  }

  set walletPlatformType(input) {
    this.idPlatformTypeFk = input?.map(item => item?.$id) ?? []
  }

  get walletDappConnection() {
    return (
      this.resource?.collectionDappConnection.getManyIds(
        this.idDappConnectionFk
      ) ?? null
    )
  }

  set walletDappConnection(input) {
    this.idDappConnectionFk = input?.map(item => item?.$id) ?? []
  }

  get filterCount(): number {
    return [
      this.idDappConnectionFk.length,
      this.idPlatformTypeFk.length,
      this.idBlockchainVersionFk.length,
      this.search ? 1 : 0,
    ].reduce((sum, value) => (sum += value), 0)
  }

  async queryAsPage() {
    return this.listWallet()
  }

  async listWallet() {
    return await Request.get(`/client/wallet`, {params: this.params})
      .name('listWallet')
      .as(this)
      .getResponse()
  }

  async listExportWallet() {
    return await Request.get(`/client/wallet/export`, {params: this.params})
      .name('listExportWallet')
      .as(this)
      .getResponse()
  }
}

export interface IWalletCollectionResourcesHolder {
  collectionDapp: DappCollection
  collectionBlockchainVersion: BlockchainVersionCollection
  collectionPlatformType: PlatformTypeCollection
  collectionDappConnection: DappConnectionCollection
}
