/**
 * Token
 * @author Simpli CLI generator
 */
import {$} from '@/facade'
import {Request} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {TokenCollection} from '@/model/collection/TokenCollection'

/* TODO: review generated class */
export class Token implements IResource {
  idTokenPk: number = 0

  imageUrl: string | null = null
  title: string | null = null
  active: boolean | null = null

  get $id() {
    return this.idTokenPk
  }

  set $id(val) {
    this.idTokenPk = val
  }

  get $tag() {
    return String(this.title)
  }

  /**
   * Lists the instances of Token
   */
  static async listToken(params: any) {
    return await Request.get(`/client/token`, {params})
      .name('listToken')
      .as(TokenCollection)
      .getData()
  }

  /**
   * Persists a new instance of Token. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persistToken() {
    return await Request.post(`/client/token`, this)
      .name('persistToken')
      .asNumber()
      .getData()
  }

  /**
   * Lists the instances of Token to export as a file
   */
  static async listExportToken(params: any) {
    return await Request.get(`/client/token/export`, {params})
      .name('listExportToken')
      .as(TokenCollection)
      .getData()
  }

  /**
   * Gets a instance of a given ID of Token
   */
  async getToken(id: number) {
    return await Request.get(`/client/token/${id}`)
      .name('getToken')
      .as(this)
      .getData()
  }

  /**
   * Deletes a instance of a given ID of Token
   */
  async removeToken() {
    return await Request.delete(`/client/token/${this.$id}`)
      .name('removeToken')
      .asNumber()
      .getData()
  }
}
