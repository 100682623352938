/**
 * PrivateCategoryCollection
 *
 * @author Simpli CLI generator
 */
import {HttpExclude, Request} from '@simpli/serialized-request'
import {PageCollection} from '@simpli/resource-collection'
import {PrivateCategory} from '@/model/resource/PrivateCategory'

/* TODO: review generated class */
@HttpExclude()
export class PrivateCategoryCollection extends PageCollection<PrivateCategory> {
  constructor() {
    super(PrivateCategory)
  }

  resource?: IPrivateCategoryCollectionResourcesHolder

  queryAsPage() {
    return this.listPrivateCategory()
  }

  async listPrivateCategory() {
    return await Request.get(`/client/private-category`, {params: this.params})
      .name('listPrivateCategory')
      .as(this)
      .getResponse()
  }

  async listExportPrivateCategory() {
    return await Request.get(`/client/private-category/export`, {
      params: this.params,
    })
      .name('listExportPrivateCategory')
      .as(this)
      .getResponse()
  }
}

export interface IPrivateCategoryCollectionResourcesHolder {}
