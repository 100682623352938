/**
 * NntTag
 * @author Simpli CLI generator
 */
import {$} from '@/facade'
import {Request} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {NntTagCollection} from '@/model/collection/NntTagCollection'

/* TODO: review generated class */
export class NntTag implements IResource {
  idNntTagPk: number = 0

  title: string | null = null
  active: boolean | null = null

  get $id() {
    return this.idNntTagPk
  }
  set $id(val) {
    this.idNntTagPk = val
  }
  get $tag() {
    return String(this.title)
  }

  /**
   * Gets a instance of a given ID of NntTag
   */
  async getNntTag(id: number) {
    return await Request.get(`/client/nnt-tag/${id}`)
      .name('getNntTag')
      .as(this)
      .getData()
  }

  /**
   * Deletes a instance of a given ID of NntTag
   */
  async removeNntTag() {
    return await Request.delete(`/client/nnt-tag/${this.$id}`)
      .name('removeNntTag')
      .asNumber()
      .getData()
  }

  /**
   * Lists the instances of NntTag
   */
  static async listNntTag(params: any) {
    return await Request.get(`/client/nnt-tag`, {params})
      .name('listNntTag')
      .as(NntTagCollection)
      .getData()
  }

  /**
   * Persists a new instance of NntTag. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persistNntTag() {
    return await Request.post(`/client/nnt-tag`, this)
      .name('persistNntTag')
      .asNumber()
      .getData()
  }

  /**
   * Lists the instances of NntTag to export as a file
   */
  static async listExportNntTag(params: any) {
    return await Request.get(`/client/nnt-tag/export`, {params})
      .name('listExportNntTag')
      .as(NntTagCollection)
      .getData()
  }
}
