/**
 * SocialType
 * @author Simpli CLI generator
 */
import {$} from '@/facade'
import {Request} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {SocialTypeCollection} from '@/model/collection/SocialTypeCollection'

/* TODO: review generated class */
export class SocialType implements IResource {
  idSocialTypePk: number = 0

  title: string | null = null
  iconUrl: string | null = null
  active: boolean | null = null

  get $id() {
    return this.idSocialTypePk
  }
  set $id(val) {
    this.idSocialTypePk = val
  }
  get $tag() {
    return String(this.title)
  }

  /**
   * Gets a instance of a given ID of SocialType
   */
  async getSocialType(id: number) {
    return await Request.get(`/client/social-type/${id}`)
      .name('getSocialType')
      .as(this)
      .getData()
  }

  /**
   * Deletes a instance of a given ID of SocialType
   */
  async removeSocialType() {
    return await Request.delete(`/client/social-type/${this.$id}`)
      .name('removeSocialType')
      .asNumber()
      .getData()
  }

  /**
   * Lists the instances of SocialType
   */
  static async listSocialType(params: any) {
    return await Request.get(`/client/social-type`, {params})
      .name('listSocialType')
      .as(SocialTypeCollection)
      .getData()
  }

  /**
   * Persists a new instance of SocialType. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persistSocialType() {
    return await Request.post(`/client/social-type`, this)
      .name('persistSocialType')
      .asNumber()
      .getData()
  }

  /**
   * Lists the instances of SocialType to export as a file
   */
  static async listExportSocialType(params: any) {
    return await Request.get(`/client/social-type/export`, {params})
      .name('listExportSocialType')
      .as(SocialTypeCollection)
      .getData()
  }
}
