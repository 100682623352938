/**
 * PrivateCategory
 * @author Simpli CLI generator
 */
import {$} from '@/facade'
import {Request} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {PrivateCategoryCollection} from '@/model/collection/PrivateCategoryCollection'

/* TODO: review generated class */
export class PrivateCategory implements IResource {
  idPrivateCategoryPk: number = 0

  title: string | null = null
  slug: string | null = null

  get $id() {
    return this.idPrivateCategoryPk
  }
  set $id(val) {
    this.idPrivateCategoryPk = val
  }
  get $tag() {
    return String(this.title)
  }

  /**
   * Gets a instance of a given ID of PrivateCategory
   */
  async getPrivateCategory(id: number) {
    return await Request.get(`/client/private-category/${id}`)
      .name('getPrivateCategory')
      .as(this)
      .getData()
  }

  /**
   * Lists the instances of PrivateCategory
   */
  static async listPrivateCategory(params: any) {
    return await Request.get(`/client/private-category`, {params})
      .name('listPrivateCategory')
      .as(PrivateCategoryCollection)
      .getData()
  }

  /**
   * Persists a new instance of PrivateCategory. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persistPrivateCategory() {
    return await Request.post(`/client/private-category`, this)
      .name('persistPrivateCategory')
      .asNumber()
      .getData()
  }

  /**
   * Lists the instances of PrivateCategory to export as a file
   */
  static async listExportPrivateCategory(params: any) {
    return await Request.get(`/client/private-category/export`, {params})
      .name('listExportPrivateCategory')
      .as(PrivateCategoryCollection)
      .getData()
  }
}
