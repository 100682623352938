/**
 * TokenCollection
 *
 * @author Simpli CLI generator
 */
import {HttpExclude, Request} from '@simpli/serialized-request'
import {PageCollection} from '@simpli/resource-collection'
import {Token} from '@/model/resource/Token'

/* TODO: review generated class */
@HttpExclude()
export class TokenCollection extends PageCollection<Token> {
  constructor() {
    super(Token)
  }

  resource?: ITokenCollectionResourcesHolder

  queryAsPage() {
    return this.listToken()
  }

  async listToken() {
    return await Request.get(`/client/token`, {params: this.params})
      .name('listToken')
      .as(this)
      .getResponse()
  }

  async listExportToken() {
    return await Request.get(`/client/token/export`, {params: this.params})
      .name('listExportToken')
      .as(this)
      .getResponse()
  }
}

export interface ITokenCollectionResourcesHolder {}
